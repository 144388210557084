<template>
  <div class="component-container">
    <h4 v-show="title">{{ title }}</h4>
    <Field
      v-if="mask"
      v-model="mValue"
      :name="name"
      :rules="rules"
      :type="type"
      :placeholder="placeholder"
      v-cleave="mask"
      @input="$emit('updated', $event)"
    ></Field>
    <Field
      v-else
      v-model="mValue"
      :name="name"
      :rules="rules"
      :type="type"
      :placeholder="placeholder"
      @input="$emit('updated', $event)"
    ></Field>
    <ErrorMessage :name="name" />
  </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate';
import useModelWrapper from '../composables/modelWrapper';

export default {
  name: 'CustomInput',
  emits: ['updated', 'update:modelValue'],
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    modelValue: {
      type: String,
      required: false,
    },
    options: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    rules: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    mask: {
      type: Object,
      required: false,
    },
    cssClass: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    return {
      mValue: useModelWrapper(props, emit),
    };
  },
};
</script>

<style scoped lang="scss">
input {
  background-color: $input-color;
  border: 1px solid #333;
  border-radius: 4px;
  display: block;
  height: 55px;
  margin: 0 auto;
  padding: 0 15px;
  transition: all 0.3s;
  width: 100%;

  &:hover,
  &:focus {
    background-color: white;
    box-shadow: none;
    outline: 0 none;
  }
}
</style>
